import { useState, useEffect } from "react"
import graphQL from "../graphql"
import {Box, Button, Container, Paper, Table, TableHead, TableBody, TableContainer, TableCell, TableRow, Typography} from "@mui/material"
import { useConfirm } from "material-ui-confirm"
import { useOktaAuth} from '@okta/okta-react';
import InfoDialog from "./InfoDialog"

const PaylogHistory = ({rerender}) => {
  const [payLog, setpayLog] = useState([])
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const confirm = useConfirm();
  const {authState} = useOktaAuth();

  useEffect( () =>  {
      if (authState.isAuthenticated) {
        const gql = new graphQL(authState.accessToken.accessToken);
        gql.getPaylog()
        .then(result => setpayLog(result.paylogs))
        .catch(e => showInfoDlg('error', e.message))
      }
  }, [authState, rerender]);

  const onDeletePayrun = payRun => {
    confirm({dialogProps: {maxWidth: 'xs'}, description: 'This action is permanent'})
    .then(() => {
      const gql = new graphQL(authState.accessToken.accessToken);

      gql.deletePayrun(payRun)
      .then(() => setpayLog(payLog.filter( pr => pr.payRun !== payRun)))
      .catch(e => showInfoDlg('error', e.message)) 
    })
    .catch(() => {})
  }
  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }
  const handleCloseInfo = () => setInfoDlgOpen(false)

  return (
    <Container sx={{paddingTop: '2em'}}>
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <Typography variant='h5'>Paylog</Typography>
        <TableContainer sx={{height: 'calc(100vh - 150px)', overflowY:'auto', marginTop: "2em"}} component={Paper}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {['Payrun', 'Period End', 'Check Date', 'Upload Date', 'Uploaded By', 'Emp. ID', 'Count'].map((heading, index) =>
                <TableCell align='right' key={index}>{heading}</TableCell>
                )}
                <TableCell key={100} align='center'>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payLog.map((logEntry, index) => 
              <TableRow key={index}>
                {[logEntry.payRun, logEntry.periodEnd, logEntry.checkDate, logEntry.uploadDate, 
                logEntry.empName, logEntry.empID, logEntry.paystubCount].map((field, index) =>
                <TableCell align='right' key={index}>{field}</TableCell>
                )}
                <TableCell align='center' key={100}>
                  <Button size="small" onClick={() => onDeletePayrun(logEntry.payRun)}>Delete</Button>
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
          
        </TableContainer>
        <InfoDialog
            show={infoDlgOpen}
            level={infoLevel}
            message={infoMessage}
            handleClose={handleCloseInfo}
          />
        </Box>
    </Container>
  )
}

export default PaylogHistory
