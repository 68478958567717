import { useState } from "react"
import { useOktaAuth } from '@okta/okta-react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography } from "@mui/material"
import InfoDialog from "./InfoDialog"

const url = 'https://pjportal.producejunction.com'
// const url = "http://localhost:8800"

const UploadW2FormsDlg = ({show, onCloseW2s}) => {
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const {authState} = useOktaAuth()
  const [formFile, setFormFile] = useState()
  const [taxYear, setTaxYear] = useState('')

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }

  const onChangeFormFile = event => {
    setFormFile(event.target.files[0]);
  }

  const handleCloseInfo = () => setInfoDlgOpen(false)

  const handleCloseW2s = () => {
    onCloseW2s()
  }

  const uploadW2Forms = () => {
    const formData = new FormData();
    formData.append('taxYear', taxYear)
    formData.append('formFile', formFile)
    const portal_url = `${url}/upload_w2_forms`;

    fetch(portal_url,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authState.accessToken.accessToken}`
        },
      }
    )
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Unauthorized')
        }
        throw new Error('Fetch error')
      }
      return response.json()
    })
    .then((result) => showInfoDlg(result.level, result.message))
    .catch((error) => {
      showInfoDlg('error', error.message)
    })
    .finally(() => handleCloseW2s())
  };

  return (
    <Box>
      <Dialog open={show} onClose={handleCloseW2s}>
        <DialogTitle>Upload W2s</DialogTitle>
        <DialogContent>
          <Box sx={{mb:2}}>
            <Typography variant="subtitle1">Tax Year</Typography>
            <TextField
              size='small'
              name="taxYear"
              autoFocus
              onChange={e => setTaxYear(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="subtitle1">W2 Form File</Typography>
            <TextField sx={{width: "100%" }}
              size='small'
              type="file"
              name="formFile"
              onChange={onChangeFormFile}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={uploadW2Forms} disabled={false}>Upload</Button>
          <Button onClick={handleCloseW2s}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <InfoDialog
        show={infoDlgOpen}
        level={infoLevel}
        message={infoMessage}
        handleClose={handleCloseInfo}
      />
    </Box>
  )
}

export default UploadW2FormsDlg
