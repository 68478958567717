import { useState } from "react"
import { useOktaAuth } from '@okta/okta-react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material"
import { format } from 'date-fns'
import InfoDialog from "./InfoDialog"

// const url = 'http://localhost:8800'
const url = 'https://pjportal.producejunction.com'

const UploadPayrollDlg = ({ show, onClosePayroll }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false)
  const [periodEnd, setPeriodEnd] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [checkDate, setCheckDate] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const [payRun, setPayrun] = useState('')
  const { authState } = useOktaAuth();

  const handleClosePayroll = () => {
    setIsSelected(false)
    setPeriodEnd(format(new Date(), 'yyyy-MM-dd'))
    setCheckDate(format(new Date(), 'yyyy-MM-dd'))
    onClosePayroll()
  }

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }

  const handleCloseInfo = () => setInfoDlgOpen(false)
  const onChangeCheckDate = e => setCheckDate(e.target.value)
  const onChangeEndPeriod = e => setPeriodEnd(e.target.value)

  const uploadPayroll = () => {
    const formData = new FormData();

    formData.append('payRun', payRun)
    formData.append('periodEnd', periodEnd)
    formData.append('checkDate', checkDate)
    formData.append('payroll', selectedFile);

    const portal_url = `${url}/upload_payroll`;

    fetch(portal_url,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authState.accessToken.accessToken}`
        },
      }
    )
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Unauthorized')
        }
        throw new Error('Fetch error')
      }
      return response.json()
    })
    .then((result) => showInfoDlg(result.level, result.message))
    .catch((error) => showInfoDlg('error', error.message))
    .finally(() => handleClosePayroll())
  };

  const changeHandler = event => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const onChangePayrun = event => setPayrun(event.target.value)

  return (
    <Box>
      <Dialog open={show} onClose={handleClosePayroll}>
        <DialogTitle>Upload Payroll</DialogTitle>
        <DialogContent>
          <Box sx={{ marginTop: '.5em' }}>
            <TextField
              id='payRun'
              size='small'
              autoFocus
              label='Payrun'
              value={payRun}
              type="number"
              onChange={onChangePayrun}
            />
          </Box>
          <Box sx={{ marginTop: "1.5em" }}>
            <TextField
              id="periodEnd"
              size='small'
              label="Period End"
              type="date"
              value={periodEnd}
              onChange={onChangeEndPeriod}
            />
            <TextField sx={{ marginLeft: "1em" }}
              id="checkDate"
              size='small'
              label="Check Date"
              type="date"
              value={checkDate}
              onChange={onChangeCheckDate}
            />
          </Box>
          <TextField sx={{ marginTop: '1.5em', width: "100%" }}
            size='small'
            type="file"
            name="file"
            onChange={changeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={uploadPayroll} disabled={!isSelected}>Upload</Button>
          <Button onClick={handleClosePayroll}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <InfoDialog
        show={infoDlgOpen}
        level={infoLevel}
        message={infoMessage}
        handleClose={handleCloseInfo}
      />
    </Box>
  )
}

export default UploadPayrollDlg
