export const gqQueries = {
  getW2: `
  query GetW2($empID: Int, $taxYear: Int) {
    w2(empID: $empID, taxYear: $taxYear) {
      ssn
      fullName
      empID
      federal {
        nameAddress
        wages
        taxes
        other
        ssWages
        ssTaxes
        medWages
        medTaxes
        box12 {
          name
          amt
        }
        box14 {
          name
          amt
        }
      }
      state {
        stateName
        firstName
        lastName
        wages
        incomeTax
      }
      local {
        taxName
        taxID
        wage
        tax
      }
    }
  }`,

  getPaystub: `
  query GetPaystub($empID: Int, $payRun: Int) {
    paystub(empID: $empID, payRun: $payRun) {
      periodEnd
      checkDate
      worklocation
      dept
      empID
      fullName
      ssn
      hireDate
      lastRaise
      grossPay
      netPay
      adjs {
        adjCode
        adjDesc
        adjAmt
        adjWage
        totAmt
        totWage
        schDesc
        allow
        addWH
      }
      pay {
        ptid
        hours
        rate
        pay
        totHours
        ytd
      }
    }
  } `,

  getPaylog: `
    query GetPaylog {
      paylogs {
        payRun
        uploadDate
        empID
        empName
        periodEnd
        checkDate
        paystubCount
      }
    }`,

  getPaystubHistory: `
    query GetPaystubHistory($empID: Int) {
      paystubHistory(empID: $empID) {
        payRun
        periodEnd
        checkDate
        grossPay
        netPay
      }
    }`,

  getW2History: `
    query GetW2History($empID: Int) {
      w2History(empID: $empID) {
        taxYear
      }
    }`,

  getAdjs: `
    query GetAdjs {
      adjs {
        adjCode
        ep
        adjType
      }
    }`,

  createAdj: `
    mutation CreateAdj($currentAdj: String, $adjCode: String, $ep: Boolean, $adjType: Int) {
      createAdj(currentAdj: $currentAdj, adjCode: $adjCode, ep: $ep, adjType: $adjType)
    }`,

  deleteAdj: `
    mutation DeleteAdj($adjCode: String) {
      deleteAdj(adjCode: $adjCode)
    }`,

  currentPayrun: `
    query CurrentPayrun {
      currentPayrun {
        payRun
        worklocation
        empID
        fullName
      }
    }`,

  currentW2s: `
    query CurrentW2s {
      currentW2s {
        ssn
        fullName
        taxYear
        empID
        state {
          stateName
        }
      }
    }`,

  deletePayrun: `
    mutation DeletePayrun($payRun: Int) {
      deletePayrun(payRun: $payRun)
    }`
}
