import { useState, useEffect } from "react"
import graphQL from "../graphql"
import {Container, Paper, Table, TableHead, TableBody, TableContainer, TableCell, TableRow, Typography} from "@mui/material"
import { Button } from "@mui/material"
import { useOktaAuth} from '@okta/okta-react';
import InfoDialog from "./InfoDialog"
import { Link } from "react-router-dom";

const PaystubHistory = () => {
  const [paystubHistory, setpaystubHistory] = useState([])
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const {authState} = useOktaAuth();

  useEffect( () =>  {
    if (authState.isAuthenticated) {
      if (! authState.idToken.claims.empID) {
        showInfoDlg('error', "No employee ID in employee record")
      }
      else {
        const gql = new graphQL(authState.accessToken.accessToken);
        gql.getPaystubHistory(authState.idToken.claims.empID)
        .then(result => setpaystubHistory(result.paystubHistory))
        .catch(e => showInfoDlg('error', e.message))
      }
    }
  }, [authState]);

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }
  const handleCloseInfo = () => setInfoDlgOpen(false)

  return (
    <Container sx={{paddingTop: '2em'}}>
      <Typography variant='h5'>Paystub History</Typography>
      <TableContainer sx={{maxWidth: 1000, marginTop: "2em"}} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {['Payrun', 'Period End', 'Check Date', 'Gross Pay', 'Net Pay'].map((heading, index) =>
              <TableCell align='right' key={index}>{heading}</TableCell>
              )}
              <TableCell key={100} align='center'>Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paystubHistory.map((stub, index) => 
            <TableRow key={index}>
              {[stub.payRun, stub.periodEnd, stub.checkDate, stub.grossPay, stub.netPay].map((field, index) =>
              <TableCell align='right' key={index}>{field}</TableCell>
              )}
              <TableCell align='center' key={100}>
                <Button component={Link} to={`/paystubs/${stub.payRun}`} size="small">Report</Button>
              </TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InfoDialog
          show={infoDlgOpen}
          level={infoLevel}
          message={infoMessage}
          handleClose={handleCloseInfo}
      />
    </Container>
  )
}
export default PaystubHistory
