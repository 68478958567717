import './App.css';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Security, LoginCallback} from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {okta_config} from './okta_config'
import { Route, useHistory } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import HomePage from './templates/HomePage';

const oktaAuth = new OktaAuth(okta_config);

const CALLBACK_PATH = '/login/callback'

function App() {
  const history = useHistory()

  const restoreOriginalUri = async (_oktaAuth, originalUri) => 
    history.replace(toRelativeUrl(originalUri, window.location.origin))

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ConfirmProvider>
        <Route path={CALLBACK_PATH} component={LoginCallback} />
        <LocalizationProvider dateAdapter={DateAdapter}>
          <HomePage/>
        </LocalizationProvider>
      </ConfirmProvider>
    </Security>
  )
}

export default App;
