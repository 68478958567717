import { Box } from "@mui/material"
// import {Typography, Link } from "@mui/material"
// import { Link as RouterLink } from "react-router-dom"

const UserMenu = () => {
  return (
    <Box sx={{marginTop: '2em'}}>
    </Box>
  )
}

export default UserMenu
