import { useOktaAuth} from '@okta/okta-react';
import { Box, Typography, Button, Tooltip} from "@mui/material"
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import UserMenu from "../components/UserMenu"
import EmployeeMenu from '../components/EmployeeMenu';
import AdminMenu from '../components/AdminMenu'
import LogoutIcon from '@mui/icons-material/Logout';
import { isAdmin } from '../pjutil';
import Adjs from '../components/Adjs';
import PaylogHistory from '../components/PaylogHistory';
import PaystubNew from '../components/PaystubNew';
import PaystubHistory from '../components/PaystubHistory';
import W2 from '../components/W2';
import W2History from '../components/W2History';
import CurrentPayrun from '../components/CurrentPayrun'
import CurrentW2s from '../components/CurrentW2s'

const HomePage2 = () => {
  const {authState, oktaAuth} = useOktaAuth()
  const login = () => oktaAuth.signInWithRedirect({originalUri: '/'});
  const logout = async () => await oktaAuth.signOut();

  if (! authState) {
    return <Box>Loading authentication . . .</Box>
  }
  else return (
    <Box sx={{height:'100vh', width:'100%'}}>
      <Box sx={{backgroundColor: 'primary.main', color:'white', height:40, display:'flex', justifyContent:'center'}}>
        <Box sx={{width:'90%', display:'flex', justifyContent: 'space-between', alignItems:'center', maxWidth:{sm:800, md: 1200}}}>
          <Box sx={{paddingLeft:'2em'}}><Typography variant='h5'>PJPortal</Typography></Box>
          <Box sx={{}}>
            {! authState || ! authState.isAuthenticated ? '' :
            <Tooltip title='Logout'>
              <LogoutIcon sx={{cursor: 'pointer'}} onClick={logout}/>
            </Tooltip>
            }
          </Box>
        </Box>
      </Box>
      <Switch>
        <SecureRoute path='/adjs' component={Adjs}/>
        <SecureRoute path='/paystubs/:payRun' component={PaystubNew}/>
        <SecureRoute path='/paystubs' component={PaystubHistory}/>
        <SecureRoute path='/w2s/:taxYear' component={W2}/>
        <SecureRoute path='/w2s' component={W2History}/>
        <SecureRoute path='/currentw2s' component={CurrentW2s}/>
        <SecureRoute path='/paylog' component={PaylogHistory}/>
        <SecureRoute path='/payrun' component={CurrentPayrun}/>
        <Route path='/'>
          <Box sx={{marginTop:'4em', display:'flex', flexDirection:'column', alignItems:'center', justifyContent: 'flex-start'}}>
            <Box>
              <Box sx={{paddingLeft:'1em'}}>
                <UserMenu/>
                {! authState || ! authState.isAuthenticated ? '' :
                <EmployeeMenu/>
                }
                {! isAdmin(authState) ? '' :
                <AdminMenu/>
                }
              </Box>
            </Box>
          </Box>
          <Box sx={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
            {! authState || authState.isAuthenticated ? '' :
            <Button variant="contained" sx={{borderRadius:'20px', padding:'0 4em', marginTop:'1em'}} onClick={login}>Login</Button>
            }
          </Box>
        </Route>
      </Switch>
    </Box>
  )
}

export default HomePage2
