import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import { Box, Button, Container } from "@mui/material"
import { useOktaAuth } from '@okta/okta-react';
import { useParams, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import graphQL from "../graphql"
import InfoDialog from "./InfoDialog";
import pjlogo from '../pjlogo_transparent.png'

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const right = { fontWeight: 600, textAlign: 'right', backgroundColor: 'GainsBoro', padding: '.25em 1em' };
const left = { fontWeight: 600, textAlign: 'left', backgroundColor: 'GainsBoro', padding: '.25em 1em' };

class PaystubLayout extends React.PureComponent {
  adjTotal() {
    let total = 0.0
    for (let adj of this.props.paystub.adjs) {
      if (!this.props.adjs[adj.adjCode]) {
        total += Math.round(adj.adjAmt * 100.0)
      }
    }
    return total / 100.0
  }
  render() {
    return (
      !this.props.paystub ? null :
        <Box sx={{
          paddingTop: '2em', display: 'flex', justifyContent: 'center', fontSize: "10pt",
          '@media print': {
            '@page': { size: 'landscape' }
          }
        }}>
          <Box sx={{ width: '900', overflow: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img alt="Produce Junction" src={pjlogo} style={{ width: '350px', height: 'auto' }} />
            </Box>

            <Box sx={{ marginTop: '2em', display: 'grid', gridTemplateColumns: 'repeat(6, auto)' }}>
              {['Name', 'Emp. ID', 'Location', 'SSN', 'Period End', 'Check Date'].map((heading, index) =>
                <Box key={index} sx={{ fontWeight: 600, backgroundColor: 'GainsBoro', padding: '.25em 1em', whiteSpace: 'nowrap' }}>
                  {heading}
                </Box>
              )}
              {[
                this.props.paystub.fullName,
                this.props.paystub.empID,
                this.props.paystub.worklocation,
                this.props.paystub.ssn,
                this.props.paystub.periodEnd,
              ].map((field, index) =>
                <Box key={index} className='ps_data_nw br'>{field}</Box>
              )}
              <Box key={100} className='ps_data_l'>{this.props.paystub.checkDate}</Box>
            </Box>

            <Box sx={{ marginTop: '2em', display: 'grid', gridTemplateColumns: 'repeat(8, auto)' }}>
              {[
                { h: 'Earnings', s: left },
                { h: 'Rate', s: right },
                { h: 'Hours', s: right },
                { h: 'Total', s: right },
                { h: 'YTD', s: right },
                { h: 'Taxes/Deductions', s: left },
                { h: 'Current', s: right },
                { h: 'YTD', s: right },
              ].map((heading, index) =>
                <Box key={index} sx={heading.s}>{heading.h}</Box>
              )}
              <Box className='br'>
                {this.props.paystub.pay.map((paySection, index) =>
                  !paySection.pay ? '' :
                    <Box key={index} className='ps_data_r'>{paySection.ptid}</Box>
                )}
              </Box>
              <Box className='br'>
                {this.props.paystub.pay.map((paySection, index) =>
                  !paySection.pay ? '' :
                    <Box key={index} className='ps_data_r'>
                      {paySection.rate ? formatter.format(paySection.rate) : ''}
                    </Box>
                )}
              </Box>
              <Box className='br'>
                {this.props.paystub.pay.map((paySection, index) =>
                  <Box key={index} className='ps_data_r'>
                    {(paySection.pay && paySection.totHours) ? paySection.hours.toFixed(2) : "\u00A0"}
                  </Box>
                )}
              </Box>
              <Box className='br'>
                {this.props.paystub.pay.map((paySection, index) =>
                  !paySection.pay ? '' :
                    <Box key={index} className="ps_data_r">{formatter.format(paySection.pay)}</Box>
                )}
              </Box>
              <Box className='br'>
                {this.props.paystub.pay.map((paySection, index) =>
                  !paySection.pay ? '' :
                    <Box key={index} className='ps_data_r'>{formatter.format(paySection.ytd)}</Box>
                )}
              </Box>

              <Box className='br'>
                {this.props.paystub.adjs.map((adj, index) =>
                  (!adj.adjAmt || this.props.adjs[adj.adjCode]) ? '' :
                    <Box key={index} className='ps_data_nw'>{adj.adjDesc}</Box>
                )}
              </Box>
              <Box className='br'>
                {this.props.paystub.adjs.map((adj, index) =>
                  (!adj.adjAmt || this.props.adjs[adj.adjCode]) ? '' :
                    <Box key={index} className='ps_data_r' >{formatter.format(adj.adjAmt)}</Box>
                )}
              </Box>
              <Box>
                {this.props.paystub.adjs.map((adj, index) =>
                  (!adj.adjAmt || this.props.adjs[adj.adjCode]) ? '' :
                    <Box key={index} className='ps_data_r' >{formatter.format(adj.totAmt)}</Box>
                )}
              </Box>
            </Box>

            <Box sx={{ marginTop: '1.5em', display: 'grid', gridTemplateColumns: 'repeat(4, auto)' }}>
              <Box className='ps_header_c'>YTD Gross</Box>
              <Box className='ps_header_c'>Gross</Box>
              <Box className='ps_header_c'>Taxes/Deductions</Box>
              <Box className='ps_header_c'>Net Pay</Box>

              <Box className='ps_data_c'>
                {formatter.format(this.props.paystub.adjs.find(adj => adj.adjCode === 'FED').totWage)}
              </Box>
              <Box className='ps_data_c'>
                {formatter.format(this.props.paystub.pay[this.props.paystub.pay.length - 1].pay)}
              </Box>
              <Box className='ps_data_c'>
                {formatter.format(this.adjTotal())}
              </Box>
              <Box className='ps_data_c' sx={{fontWeight: 800 }}>
                {formatter.format(this.props.paystub.pay[this.props.paystub.pay.length - 1].pay - this.adjTotal())}
              </Box>
            </Box>
          </Box>
        </Box>
    )
  }
}

const PaystubNew = () => {
  const { payRun } = useParams()
  const { authState } = useOktaAuth()
  const [paystub, setPaystub] = useState(null)
  const [adjs, setAdjs] = useState({})
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const location = useLocation()

  let empID = ''

  if (location.state) {
    empID = location.state.empID
  }
  useEffect(() => {
    const currentID = empID || authState.idToken.claims.empID
    if (!currentID) {
      showInfoDlg('error', "No employee id")
    }
    else {
      const gql = new graphQL(authState.accessToken.accessToken);
      gql.getPaystub(parseInt(currentID), parseInt(payRun))
        .then(result => {
          const paySections = result.paystub.pay

          let newPay = [];

          const salarySection = paySections.find(ps => ps.ptid.match(/Salary/))

          if (salarySection) {
            newPay.push(salarySection)
          } else {
            const wageSections = paySections.filter(ps => ps.ptid.match(/Hours/))
            newPay.push(...wageSections)
          }
          const bonusSection = paySections.find(ps => ps.ptid.match(/Bonus/))

          if (bonusSection) {
            newPay.push(bonusSection)
          }
          if (newPay.length > 1) {
            // A total section is only required if there are multiple earnings lines,
            // like salary + bonus, or hourly wages + overtime wages
            const totalSection = paySections.find(ps => ps.ptid.match(/Total/))
            newPay.push(totalSection)
          }

/*
          for (let section of result.paystub.pay) {
            if (section.ptid.match(/Salary|Bonus/)) {
              newPay = [];
              newPay.push(section)
              break;
            }
            if (section.totHours && (section.ptid !== 'Total' || newPay.length > 1)) {
              newPay.push(section)
            }
          }
*/
          result.paystub.pay = newPay;
          setPaystub(result.paystub)
        })
        .catch(e => showInfoDlg('error', e.message))

      gql.getAdjs()
        .then(result => {
          const newAdjs = {}
          for (let adj of result.adjs) {
            newAdjs[adj.adjCode] = adj.ep
          }
          setAdjs(newAdjs)
        })
        .catch(e => showInfoDlg('error', e.message))
    }
  }, [authState, payRun, empID])

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }
  const handleCloseInfo = () => setInfoDlgOpen(false)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  return (
    <Container sx={{ position: 'absolute', top: 50, bottom: 0, left: 0, right: 0, overflow: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <PaystubLayout paystub={paystub} payRun={payRun} adjs={adjs} ref={componentRef} />
        <Button size='large' sx={{ marginTop: '4em' }} onClick={handlePrint}>Print</Button>
      </Box>
      <InfoDialog
        show={infoDlgOpen}
        level={infoLevel}
        message={infoMessage}
        handleClose={handleCloseInfo}
      />
    </Container>
  )
}

export default PaystubNew
