import { useState, useEffect } from "react"
import graphQL from "../graphql"
import {Paper, Table, TableHead, TableBody, TableContainer, TableCell, TableRow, Typography} from '@mui/material'
import { Box } from "@mui/system"
import { Container, Checkbox, Button, Radio, RadioGroup } from "@mui/material"
import { FormControlLabel, TextField } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check'
import { useOktaAuth} from '@okta/okta-react';
import InfoDialog from "./InfoDialog"
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { useConfirm } from "material-ui-confirm"

const Adjs = () => {
  const { authState } = useOktaAuth();
  const [adjs, setAdjs] = useState([])
  const [adjType, setAdjType] = useState('')
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const [adjCode, setAdjCode] = useState('')
  const [ep, setEP] = useState(false)
  const [showAdjDlg, setShowAdjDlg] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [currentAdj, setCurrentAdj] = useState('')
  const confirm = useConfirm();

  useEffect( () =>  {
    if (authState.isAuthenticated) {
      const gql = new graphQL(authState.accessToken.accessToken);
      gql.getAdjs()
      .then(result => setAdjs(result.adjs))
      .catch(e => showInfoDlg('error', e.message))
    }
  }, [authState]);

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }
  const handleCloseInfo = () => setInfoDlgOpen(false)

  const onDeleteAdj = adjCode => {
    confirm({dialogProps: {maxWidth: 'xs'}, description: 'This action is permanent'})
    .then(() => {
      const gql = new graphQL(authState.accessToken.accessToken);

      gql.deleteAdj(adjCode)
      .then(() => setAdjs(adjs.filter(adj => adj.adjCode !== adjCode)))
      .catch(e => showInfoDlg('error', e.message)) 
    })
    .catch(() => {})
  }
  const createAdj = () => {
    if (! adjCode) {
      showInfoDlg('error', 'Enter an adjustment code, ie FICAE')
      return
    }
    const gql = new graphQL(authState.accessToken.accessToken);

    gql.createAdj(currentAdj || adjCode, adjCode, ep, parseInt(adjType))
    .then(() => {
      if (currentAdj) {
        setAdjs(adjs.map(adj => adj.adjCode === currentAdj ? {adjCode: adjCode, ep: ep, adjType: adjType} : adj))
      }
      else {
        setAdjs([{adjCode: adjCode, ep: ep, adjType: adjType}, ...adjs])
      }
    })
    .catch(e => showInfoDlg('error', e.message))
    setShowAdjDlg(false)
  }
  const onChangeAdjCode = event => setAdjCode(event.target.value)
  const onEP = event => setEP(event.target.checked)

  const onOpenAdj = () => {
    setEP(false)
    setAdjCode('')
    setDialogTitle('Add Adjustment')
    setCurrentAdj('')
    setShowAdjDlg(true)
  }

  const onOpenEditAdj = (adjCode, ep, adjType) => {
    setEP(ep)
    setAdjCode(adjCode)
    setDialogTitle('Edit Adjustment')
    setCurrentAdj(adjCode)
    setAdjType(adjType.toString())
    setShowAdjDlg(true)
  }

  const onSelectAdjType = (event, value) => {
    setAdjType(value)
    if (value === '2') {
      setEP(true)
    }
    else if (value === '3') {
      setEP(false)
    }
  }

  const adjTypes = ['', 'Tax', 'Benefit', 'Other']

  return (
    <Container sx={{paddingTop: '2em'}}>
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <Typography variant='h5'>Adjustments</Typography>
      <TableContainer sx={{width: '100%', maxWidth:800, height: 'calc(100vh - 170px)', marginTop: '1em', overflow:'auto'}} component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {['Adjcode', 'Type', 'Employer Paid'].map((heading, index) =>
              <TableCell key={index}>{heading}</TableCell>
              )}
              {['Edit', 'Delete'].map((heading, index) =>
              <TableCell align='center' key={index+10}>{heading}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
          {adjs.map(adj =>
            <TableRow key={adj.adjCode}>
              <TableCell>{adj.adjCode}</TableCell>
              <TableCell>{adjTypes[adj.adjType]}</TableCell>
              <TableCell> {adj.ep ? <CheckIcon fontSize='small' sx={{position:'relative', left:'1.5em'}} /> : ''} </TableCell>
              <TableCell align='center'><Button size="small" onClick={() => onOpenEditAdj(adj.adjCode, adj.ep, adj.adjType)}>Edit</Button></TableCell>
              <TableCell align='center'><Button size="small" onClick={() => onDeleteAdj(adj.adjCode)}>Delete</Button></TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{marginTop: '1em'}}  >
        <Button onClick={onOpenAdj}>New</Button>
      </Box>
      </Box>
      <InfoDialog
          show={infoDlgOpen}
          level={infoLevel}
          message={infoMessage}
          handleClose={handleCloseInfo}
        />
      <Dialog open={showAdjDlg} onClose={() => setShowAdjDlg(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
            <Box>
              <TextField sx={{marginTop:'.5em'}} size='small' value={adjCode} onChange={onChangeAdjCode} label="AdjCode" autoFocus />
            </Box>
            <Box sx={{marginTop: '1em'}} >
              <RadioGroup value={adjType} onChange={onSelectAdjType} row>
                <FormControlLabel value="1" control={<Radio size='small'/>} label='Tax'/>
                <FormControlLabel value="2" control={<Radio size='small'/>} label='Benefit'/>
                <FormControlLabel value="3" control={<Radio size='small'/>} label='Other'/>
              </RadioGroup>
            </Box>
            <Box> 
              <FormControlLabel control={<Checkbox size='small' checked={ep} onChange={onEP}/>} label='Employer Paid'/>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={createAdj}>Okay</Button>
          <Button onClick={() => setShowAdjDlg(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
    )
}

export default Adjs
