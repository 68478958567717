const CLIENT_ID = '0oa1if0ygzmcJkJY25d7'
const ISSUER = 'https://dev-13873798.okta.com/oauth2/default'
const CALLBACK_PATH = '/login/callback'
const REDIRECT_URI = `${window.location.origin}${CALLBACK_PATH}`
// const LOGOUT_URI = `${window.location.origin}/logged_out`
const SCOPES = 'openid profile email';
const TESTING_DISABLE_HTTPS = true;

export const okta_config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  disableHttpsCheck: TESTING_DISABLE_HTTPS,
  // postLogoutRedirectUri: LOGOUT_URI
};
