import { useState } from "react"
import { Box, Typography, Link } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import UploadPayrollDlg from './UploadPayrollDlg'
import UploadW2FormsDlg from "./UploadW2FormsDlg"
//import UploadW2sDlg from '../components/UploadW2sDlg'

const AdminMenu = () => {
  const [showPayrollDlg, setShowPayrollDlg] = useState(false)
  const onClosePayroll = () => setShowPayrollDlg(false)
  const [showW2sDlg, setShowW2sDlg] = useState(false)
  const onCloseW2s = () => setShowW2sDlg(false)

  return (
    <Box>
      <Link sx={{textDecoration:'none'}} component={RouterLink} to="/adjs">
        <Typography>Adjustments</Typography>
      </Link>
      <Link sx={{textDecoration:'none'}} component={RouterLink} to="/paylog">
        <Typography>Paylog</Typography>
      </Link>
      <Link sx={{textDecoration:'none'}} component={RouterLink} to="/payrun">
        <Typography>Current Payrun</Typography>
      </Link>
      <Link sx={{textDecoration:'none'}} component={RouterLink} to="/currentw2s">
        <Typography>Current W2s</Typography>
      </Link>
      <Link sx={{textDecoration:'none', cursor:'pointer'}}>
        <Typography onClick={() => setShowPayrollDlg(true)}>Upload Payrun</Typography>
      </Link>
      <Link sx={{textDecoration:'none', cursor:'pointer'}}>
        <Typography onClick={() => setShowW2sDlg(true)}>Upload W2s</Typography>
      </Link>
      <UploadPayrollDlg show={showPayrollDlg} onClosePayroll={onClosePayroll}/>
      <UploadW2FormsDlg show={showW2sDlg} onCloseW2s={onCloseW2s}/>
    </Box>
  )
}

export default AdminMenu
