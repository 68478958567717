import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material"
import { useOktaAuth } from '@okta/okta-react';
import { useParams, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import graphQL from "../graphql"
import InfoDialog from "./InfoDialog";

const pjInfo = `
Produce Junction
2119 Center Square Rd
Swedesboro, NJ 08085`

class W2Layout extends React.PureComponent {
  render() {
    const nameAddress = this.props?.w2?.federal?.nameAddress || ''
    const employeeInfoArray = nameAddress.split("\n")
    const pjInfoArray = pjInfo.split("\n")

    const stateRE = /(\S+)\s+\S+$/
    const employeeCityStateZip = employeeInfoArray.length ? employeeInfoArray[employeeInfoArray.length-1] : ''
    const match = employeeCityStateZip.match(stateRE)
    const stateAbbrev = match?.[1] || ''

    return (
      !this.props.w2 ? null :
        <Box sx={{
          paddingTop: '2em',
          paddingLeft: '2em',
          '@media print': {
            '@page': { size: 'landscape' }
          }
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{width:890}}>
              <Typography variant='h6'>W2 - Employee Information Copy</Typography>
              <Box sx={{ 
                border: '1px solid black', 
                marginTop: '2em', 
                padding: '0', 
                fontSize: '9pt',
              }}>
                <Box className='bb' sx={{ display: 'flex' }}>
                  <Box className='w2_data_box'>
                  <Box sx={{fontFamily:'Arial', fontSize:'20pt', position:'relative', top:'.3em', pl:10}}>
                    {this.props.taxYear}
                  </Box>
                  </Box>
                  <Box className='bl br' sx={{ ml: '10em' }}>
                    <Box className='w2_heading'><b>a</b> Employee's social security number</Box>
                    <Box className='w2_value'>{this.props.w2.ssn}</Box>
                  </Box>
                </Box>
                <Box className='w2_top'>
                  <Box className='ein w2_data_box br bb'>
                    <Box className='w2_heading'><b>b</b> Employer identification number (EIN)</Box>
                    <Box className='w2_value'>23-2217701</Box>
                  </Box>
                  <Box className='wages w2_data_box br bb'>
                    <Box className='w2_heading'><b>1</b> Wages, tips, other compensation</Box>
                    <Box className='w2_value'>{this.props.w2.federal.wages.toFixed(2)}</Box>
                  </Box>
                  <Box className='fed_tax w2_data_box bb'>
                    <Box className='w2_heading'><b>2</b> Federal income tax withheld</Box>
                    <Box className='w2_value'>{this.props.w2.federal.taxes.toFixed(2)}</Box>
                  </Box>
                  <Box className='employer_name w2_data_box br bb'>
                    <Box className='w2_heading'><b>c</b> Employer's name, address and ZIP code</Box>
                    <Box className='w2_value'>
                      <Box>
                        {pjInfoArray.map((infoLine, index) =>
                          <Box key={index}>{infoLine}</Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className='ss_wages w2_data_box br bb'>
                    <Box className='w2_heading'><b>3</b> Social security wages</Box>
                    <Box className='w2_value'>{this.props.w2.federal.ssWages.toFixed(2)}</Box>
                  </Box>
                  <Box className='ss_tax w2_data_box bb'>
                    <Box className='w2_heading'><b>4</b> Social security tax withheld</Box>
                    <Box className='w2_value'>{this.props.w2.federal.ssTaxes.toFixed(2)}</Box>
                  </Box>
                  <Box className='med_wages w2_data_box br bb'>
                    <Box className='w2_heading'><b>5</b> Medicare wages and tips</Box>
                    <Box className='w2_value'>{this.props.w2.federal.medWages.toFixed(2)}</Box>
                  </Box>
                  <Box className='med_tax w2_data_box bb'>
                    <Box className='w2_heading'><b>6</b> Medicare tax withheld</Box>
                    <Box className='w2_value'>{this.props.w2.federal.medTaxes.toFixed(2)}</Box>
                  </Box>
                  <Box className='ss_tips w2_data_box br bb'>
                    <Box className='w2_heading'><b>7</b> Social security tips</Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='allocated_tips w2_data_box bb'>
                    <Box className='w2_heading'><b>8</b> Allocated tips</Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='control_num w2_data_box bb br'>
                    <Box className='w2_heading'><b>d</b> Control number</Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='box9 w2_data_box br bb'>
                    <Box className='w2_heading'><b>9</b></Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='dependent_benefits w2_data_box bb'>
                    <Box className='w2_heading'><b>10</b> Dependent care benefits</Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='employee_info w2_data_box br bb'>
                    <Box className='w2_heading'><b>e</b> Employee's name address and ZIP code</Box>
                    <Box className='w2_value'>
                      <Box>
                        {employeeInfoArray.map((infoLine, index) =>
                          <Box key={index}>{infoLine}</Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className='non_qual_plans w2_data_box br bb'>
                    <Box className='w2_heading'><b>11</b> Nonqualified plans</Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='box12a w2_data_box bb'>
                    <Box className='w2_heading'><b>12a</b> See instructions for box 12</Box>
                    <Box className='w2_value'>
                      {! this.props.w2.federal?.box12?.length ? null :
                      <Box sx={{display:'flex', columnGap:'2em'}}>
                        <Box sx={{width:'25px'}}>{this.props.w2.federal.box12[0].name}</Box>
                        <Box >{this.props.w2.federal.box12[0].amt.toFixed(2)}</Box>
                      </Box>
                      }
                    </Box>
                  </Box>
                  <Box className='box13 w2_data_box br bb'>
                    <Box className='w2_heading'><b>13</b></Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='box12b w2_data_box bb'>
                    <Box className='w2_heading'><b>12b</b></Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='box14 w2_data_box br bb'>
                    <Box className='w2_heading'><b>14</b> Other</Box>
                    <Box className='w2_value'>
                      <Box>
                      {! this.props.w2.federal?.box14?.length ? null : 
                      this.props.w2.federal.box14.map(box14 => 
                        <Box sx={{display:'flex', columnGap:'2em'}}>
                          <Box sx={{width:'50px'}}>{box14.name}</Box>
                          <Box sx={{width:'50px', textAlign:'right'}}>{box14.amt.toFixed(2)}</Box>
                        </Box>
                      )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className='box12c w2_data_box bb'>
                    <Box className='w2_heading'><b>12c</b></Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                  <Box className='box12d w2_data_box bb'>
                    <Box className='w2_heading'><b>12d</b></Box>
                    <Box className='w2_value'>&nbsp;</Box>
                  </Box>
                </Box>
                <Box className='w2_bottom'>
                  <Box className='w2_data_box br'>
                    <Box className='w2_heading'><b>15</b> State</Box>
                    <Box className='w2_value'>{stateAbbrev}</Box>
                  </Box>
                  <Box className='w2_data_box br'>
                    <Box className='w2_heading'>Employer's state ID number</Box>
                    <Box className='w2_value'>232217701</Box>
                  </Box>
                  <Box className='w2_data_box br'>
                    <Box className='w2_heading'><b>16</b> State wages, tips, etc.</Box>
                    <Box className='w2_value'>
                      {this.props.w2.state.wages ? this.props.w2.state.wages.toFixed(2) : null}
                    </Box>
                  </Box>
                  <Box className='w2_data_box br'>
                    <Box className='w2_heading'><b>17</b> State income tax</Box>
                    <Box className='w2_value'>
                      {this.props.w2.state.incomeTax ? this.props.w2.state.incomeTax.toFixed(2) : null}
                    </Box>
                  </Box>
                  <Box className='w2_data_box br'>
                    <Box className='w2_heading'><b>18</b> Local wages, tips, etc.</Box>
                    {this.props.w2.local.map((localTax, index) =>
                      localTax.wage ?
                        <Box className='w2_value' key={index}>{localTax.wage.toFixed(2)}</Box> :
                        <Box className='w2_value' key={index}>&nbsp;</Box>
                    )}
                  </Box>
                  <Box className='w2_data_box br'>
                    <Box className='w2_heading'><b>19</b> Local income tax</Box>
                    {this.props.w2.local.map((localTax, index) =>
                      <Box className='w2_value' key={index}>{localTax.tax.toFixed(2)}</Box>
                    )}
                  </Box>
                  <Box className='w2_data_box'>
                    <Box className='w2_heading'><b>20</b> Locality name</Box>
                    {this.props.w2.local.map((localTax, index) =>
                      <Box className='w2_value' key={index}>{localTax.taxName}</Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
    )
  }
}
const W2 = () => {
  const {taxYear} = useParams()
  const {authState} = useOktaAuth()
  const [w2, setW2] = useState(null)
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const location = useLocation()

  let empID = ''

  if (location.state) {
    empID = location.state.empID
  }

  useEffect(() => {
    const currentID = empID || authState.idToken.claims.empID 
    if (! currentID) {
      showInfoDlg('error', "No employee id")
    }
    else {
      const gql = new graphQL(authState.accessToken.accessToken);
      gql.getW2(parseInt(currentID), parseInt(taxYear))
      .then(result => {
        setW2(result.w2)
      })
      .catch(e => showInfoDlg('error', e.message))
    }
  }, [authState, taxYear, empID])

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }
  const handleCloseInfo = () => setInfoDlgOpen(false)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <Box sx={{display:'flex', flexDirection:'column'}}>
      <W2Layout w2={w2} taxYear={taxYear} ref={componentRef}/>
      <Button size='large' sx={{marginTop: '4em'}} onClick={handlePrint}>Print</Button>
      <InfoDialog
          show={infoDlgOpen}
          level={infoLevel}
          message={infoMessage}
          handleClose={handleCloseInfo}g
      />
    </Box>
  )
}
export default W2
