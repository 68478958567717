import { Dialog, DialogContent, DialogActions, Button } from "@mui/material"

const InfoDialog = ({show, message, handleClose}) => {
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>Okay</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoDialog
