import {gqQueries} from './gqQueries'

// const url = 'http://localhost:4000/graphql'
const url = 'https://pjportal.producejunction.com/graphql'

class graphQL {
  constructor(accessToken) {
    this.access_token = accessToken
  }
  gqFetch(query, vars) {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.access_token}`
      },
      body: JSON.stringify({query: query, variables: vars})
    }).then(response => {
      if (! response.ok) {
        if (response.status === 401) {
          throw new Error('Unauthorized')
        }
        else {
          throw new Error('Fetch error')
        }
      }
      return response.json();
    }).then(json => {
      if (json.data) {
        return json.data
      }
      else if (json.level === 'error') {
        throw new Error(json.message)
      }
      else if (json.errors) {
        throw new Error(json.errors.map(error => error.message).join('\n'))
      }
    })
  }

  getW2(empID, taxYear) {
    return this.gqFetch(gqQueries.getW2, {empID: empID, taxYear: taxYear})
  }

  getPaystub(empID, payRun) {
    return this.gqFetch(gqQueries.getPaystub, {empID: empID, payRun: payRun})
  }

  getPaylog() {
    return this.gqFetch(gqQueries.getPaylog)
  }

  getAdjs() {
    return this.gqFetch(gqQueries.getAdjs)
  }

  getPaystubHistory(empID) {
    return this.gqFetch(gqQueries.getPaystubHistory, {empID: parseInt(empID)})
  }

  getW2History(empID) {
    return this.gqFetch(gqQueries.getW2History, {empID: parseInt(empID)})
  }

  deletePayrun(payRun) {
    return this.gqFetch(gqQueries.deletePayrun, {payRun: parseInt(payRun)})
  }

  createAdj(currentAdj, adjCode, ep, adjType) {
    return this.gqFetch(gqQueries.createAdj, {currentAdj: currentAdj, adjCode: adjCode, ep: ep, adjType: adjType})
  }

  deleteAdj(adjCode) {
    return this.gqFetch(gqQueries.deleteAdj, {adjCode: adjCode})
  }

  currentPayrun() {
    return this.gqFetch(gqQueries.currentPayrun)
  }
      
  currentW2s() {
    return this.gqFetch(gqQueries.currentW2s)
  }
}
export default graphQL

