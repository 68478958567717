import { Box, Typography, Link } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

const EmployeeMenu = () => {
  return (
    <Box>
      <Link sx={{textDecoration:'none'}} component={RouterLink} to="/paystubs"><Typography>Paystubs</Typography></Link>
      <Link sx={{textDecoration:'none'}} component={RouterLink} to="/w2s"><Typography>W2s</Typography></Link>
    </Box>
  )
}

export default EmployeeMenu
