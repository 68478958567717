import { useState, useEffect } from "react"
import graphQL from "../graphql"
import {Container, Paper, Table, TableHead, TableBody, TableContainer, TableCell, TableRow, Typography} from "@mui/material"
import { Button } from "@mui/material"
import { useOktaAuth} from '@okta/okta-react';
import InfoDialog from "./InfoDialog"
import { Link } from "react-router-dom";

const W2History = () => {
  const [w2History, setW2History] = useState([])
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const {authState} = useOktaAuth();

  useEffect( () =>  {
    if (authState.isAuthenticated) {
      if (! authState.idToken.claims.empID) {
        showInfoDlg('error', "No employee ID in employee record")
      }
      else {
        const gql = new graphQL(authState.accessToken.accessToken);
        gql.getW2History(authState.idToken.claims.empID)
        .then(result => setW2History(result.w2History))
        .catch(e => showInfoDlg('error', e.message))
      }
    }
  }, [authState]);

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }
  const handleCloseInfo = () => setInfoDlgOpen(false)

  return (
    <Container sx={{paddingTop: '2em'}}>
      <Typography variant='h5'>Annual Earnings and Taxes </Typography>
      <TableContainer sx={{maxWidth: 1000, marginTop: "2em"}} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {['Tax Year'].map((heading, index) =>
              <TableCell align='right' key={index}>{heading}</TableCell>
              )}
              <TableCell key={100} align='center'>Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {w2History.map((w2, index) => 
            <TableRow key={index}>
              {[w2.taxYear].map((field, index) =>
              <TableCell align='right' key={index}>{field}</TableCell>
              )}
              <TableCell align='center' key={100}>
                <Button component={Link} to={`/w2s/${w2.taxYear}`} size="small">Report</Button>
              </TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InfoDialog
          show={infoDlgOpen}
          level={infoLevel}
          message={infoMessage}
          handleClose={handleCloseInfo}
      />
    </Container>
  )
}
export default W2History

