import { useState, useEffect } from "react"
import graphQL from "../graphql"
import {Box} from '@mui/system'
import {Paper, Table, TableHead, TableBody, TableContainer, TableCell, TableRow, Typography} from "@mui/material"
import { Container, Button, TextField} from "@mui/material"
import { useOktaAuth} from '@okta/okta-react';
import InfoDialog from "./InfoDialog"
import { Link, useHistory} from "react-router-dom"
import Autocomplete from '@mui/material/Autocomplete';

const CurrentPayrun = () => {
  const { authState } = useOktaAuth();
  const history = useHistory();
  const [payrun, setPayrun] = useState([])
  const [infoDlgOpen, setInfoDlgOpen] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [infoLevel, setInfoLevel] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState('')
  
  useEffect( () =>  {
    if (authState.isAuthenticated) {
      const gql = new graphQL(authState.accessToken.accessToken);
      gql.currentPayrun()
      .then(result => setPayrun(result.currentPayrun))
      .catch(e => showInfoDlg('error', e.message))
    }
  }, [authState]); 

  const showInfoDlg = (level, message) => {
    setInfoMessage(message)
    setInfoLevel(level)
    setInfoDlgOpen(true)
  }
  const handleCloseInfo = () => setInfoDlgOpen(false)

  const onChangeEmployee = (event, value) => {
    const path = `/paystubs/${value.payRun}`;
    const state = {empID: value.empID}

    history.push({pathname: path, state: state})
  }

  const onInputChangeEmployee = (event, value) => {
    setSelectedEmployee(value)
  }

  return (
    <Container sx={{paddingTop: '2em'}}>
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <Typography sx={{marginTop: '1em'}} variant='h5'>Current Payrun</Typography>

        <Autocomplete
          sx={{marginTop: '1em'}}
          size='small'
          selectOnFocus
          inputValue={selectedEmployee}
          clearOnEscape
          onChange = {onChangeEmployee}
          onInputChange = {onInputChangeEmployee}
          handleHomeEndKeys
          options={payrun}
          getOptionLabel={paystub => `${paystub.fullName}`}
          style={{ width: 300 }}
          renderInput = {fields => <TextField {...fields} label="Search Employees" variant="outlined" />}
        />
        <TableContainer sx={{width: '100%', maxWidth:'800px', height: 'calc(100vh - 200px)', marginTop: '1em'}} component={Paper}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {['Employee', 'Emp ID', 'Location'].map((heading, index) =>
                <TableCell key={index}>{heading}</TableCell>
                )}
                <TableCell align='center' key={10}>Report</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {payrun.map(p =>
              <TableRow key={p.empID}>
                <TableCell>{p.fullName}</TableCell>
                <TableCell>{p.empID}</TableCell>
                <TableCell>{p.worklocation}</TableCell>
                <TableCell align='center' key={100}>
                  <Button
                    size="small" 
                    component={Link}
                    to={{
                      pathname: `/paystubs/${p.payRun}`,
                      state: {empID: p.empID}
                  }}>Report
                  </Button>
                </TableCell>
              </TableRow>
            )}
            </TableBody>
          </Table>
        </TableContainer>
  
        <InfoDialog
            show={infoDlgOpen}
            level={infoLevel}
            message={infoMessage}
            handleClose={handleCloseInfo}
        />      
      </Box>
    </Container>
  )
}

export default CurrentPayrun
